import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import {
  ProjectWrapper,
  ProjectBlock,
} from "./project.stc";
import clientConfig from "../../../client-config";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import PortableText from "../../components/shared/portableText";

const Project = ({
  image,
  slug,
  title,
  summary,
  client,
  category,
  isEven,
  ...restProps
}) => {
  const { WrapperStyle } = restProps;

  const imgFluid = getFluidGatsbyImage(
    image.asset._ref,
    { maxWidth: 1060, maxHeight: 600, quality: 100 },
    clientConfig.sanity
  );

  return (
    <ProjectWrapper {...WrapperStyle}>
      <div>
        <ProjectBlock fluid={imgFluid} backgroundColor={`#cccccc`}>
          <div className="overlay"></div>
          <div className="contentWrapper">
            {title && (
              <Link to={`/${slug}`}>
                <h3>{title}</h3>
              </Link>
            )}
            {summary && <PortableText blocks={summary} />}

            <Link
              to={`/${slug}`} 
            >
              <button>View Project</button>
            </Link>
          </div>
        </ProjectBlock>
      </div>
    </ProjectWrapper>
  );
};

Project.propTypes = {
  HeadingStyle: PropTypes.object,
  TextStyle: PropTypes.object,
  WrapperStyle: PropTypes.object,
};

Project.defaultProps = {
  HeadingStyle: {
    as: "h6",
    color: "#001c43",
    fontSize: "12px",
    fontweight: 700,
    letterspacing: "2px",
    mb: "12px",
    responsive: {
      small: {
        letterspacing: "1px",
        mb: "5px",
      },
    },
  },
  TextStyle: {
    fontSize: "12px",
    letterspacing: "1px",
    mb: 0,
  },
  WrapperStyle: {
    responsive: {
      medium: {
        mt: "0px",
      },
    },
  },
};

export default Project;